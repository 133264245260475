import * as browser from 'react-device-detect';

const notAcceptable = [
  browser.isEdge && Number(browser.browserVersion) < 80,
  browser.isSafari && Number(browser.browserVersion) < 14,
];

const isValidBrowser = browser.isMobile || browser.isIPad13 || notAcceptable.findIndex((b) => b) === -1;

export default isValidBrowser;
