import dayjs from 'dayjs';

export default function getFileName(extension: string, loadedFileInfo?: { fileName?: string }, copy = true) {
  if (loadedFileInfo && loadedFileInfo.fileName) {
    const pattern = new RegExp('^(.+)\\.ffb$', 'i');
    return `${copy ? 'Copy of ' : ''}${loadedFileInfo.fileName.replace(pattern, '$1')}.${extension}`;
  }

  return `FlatFrogBoard-${dayjs().format('YYYY-MM-DD HHmm')}.${extension}`;
}
